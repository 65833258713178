AFRAME.registerComponent("ui-button", {
    schema: {
        label: {type: "string", default: "Button"},
        color: {type: "color", default: "#2568e4"},
        hoverColor: {type: "color", default: "#5c8eeb"},
        width: {type: "number", default: 1},
        height: {type: "number", default: 0.2},
    },
    init: function () {
        const data = this.data;
        const el = this.el;

        this.bindMethods();

        el.setAttribute("geometry", {
            primitive: "plane",
            width: data.width,
            height: data.height,
        });
        el.setAttribute("material", {
            color: data.color,
            shader: "flat",
        });

        const textEl = document.createElement("a-text");
        textEl.setAttribute("value", data.label);
        textEl.setAttribute("color", "white");
        textEl.setAttribute("baseline", "center");
        textEl.setAttribute("align", "center");
        //textEl.setAttribute("height", 0);
        textEl.setAttribute("width", data.width * 2);
        //textEl.setAttribute("wrapCount", 30);
        el.appendChild(textEl);
        this.textEl = textEl;

        // Add interaction handlers
        this.el.addEventListener("raycaster-intersected", this.onMouseEnter);
        this.el.addEventListener("raycaster-intersected-cleared", this.onMouseLeave);
    },
    update: function (oldData) {
        const modifiedData = AFRAME.utils.diff(oldData, this.data);

        for (const modifiedKey in modifiedData) {
            switch (modifiedKey) {
                case "height":
                case "width":
                    this.el.setAttribute("geometry", {
                        primitive: "plane",
                        width: this.data.width,
                        height: this.data.height,
                    });
                    break;

                case "label":
                    this.textEl.setAttribute("value", this.data.label);
                    break;

                case "color":
                    this.el.setAttribute("material", {
                        color: this.data.color,
                        shader: "flat",
                    });
                    break;
            }
        }
    },

    onMouseEnter: function () {
        this.el.setAttribute("material", {
            color: this.data.hoverColor,
            shader: "flat",
        });
    },

    onMouseLeave: function () {
        this.el.setAttribute("material", {
            color: this.data.color,
            shader: "flat",
        });
    },

    remove: function () {
        this.el.removeEventListener("raycaster-intersected", this.onMouseEnter);
        this.el.removeEventListener("raycaster-intersected-cleared", this.onMouseLeave);
    },

    /**
     * bind methods used in callbacks
     */
    bindMethods: function () {
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    },
});
